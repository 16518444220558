window.React = require('react');
window.ReactDOM = require('react-dom');
window.ReactRouter = require('react-router');
window.ReactRouterDOM = require('react-router-dom');
window.EmotionCore = require('@emotion/core');
window.EmotionStyled = require('@emotion/styled');
window.Axios = require('axios');
window.Noty = require('noty');
window.NProgress = require('nprogress');
window.ObjectPath = require('object-path');

window.ccNormalizeCss = () => {
  require('normalize.css/normalize.css');
};
window.ccDefaultCss = () => {
  require('./default.css');
};
window.ccUtilsCss = () => {
  require('nprogress/nprogress.css');
  require('noty/lib/noty.css');
  require('noty/lib/themes/relax.css');
};

/* to build libs -> yarn build */
